.FloorMapTable {
	position: absolute;
	top: 500px;
	left: 1300px;
	height: 100%;
	width: 100%;
	background-color: white;
	border-radius: 8px;

	transition: ease width 0.3s, ease height 0.3s, ease margin 0.3s, ease z-index 0.5s;

	box-shadow: 0 4px 12px 2px rgba(0,0,0,0.1);
	z-index: 5;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    cursor: pointer;
	z-index: 6;
}

html.dark .FloorMapTable {
	background-color: #1a1a1a;
	color: white;
}

.FloorMapTable.active {
	height: 140px;
	width: 250px;
	z-index: 8;
	transition: ease width 0.3s, ease height 0.3s, ease margin 0.3s;
}

.FloorMapTable__table-number-container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: calc(100% - 8px);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease all 0.3s;
}

html.dark .FloorMapTable__table-number-container {
	color: white;
}

.FloorMapTable.active .FloorMapTable__table-number-container {
	height: 84px;
	left: 26px;
}

.FloorMapTable__table-number {
	font-size: 18px;
	font-weight: 400;
	transition: ease all 0.3s;
}

.FloorMapTable.active .FloorMapTable__table-number {

	flex-grow: 1;
	font-size: 22px;
}

.FloorMapTable__status-color-bar {
	position: absolute;
	bottom: 6px;
	left: 6px;

	display: block;

	width: calc(100% - 12px);
	height: 6px;

	border-radius: 8px;

	background-color: #ff0000;

	transition: ease all 0.15s;
}

.FloorMapTable.available .FloorMapTable__status-color-bar {
	background-color: #60C21D;
}

.FloorMapTable.available.obfuscated .FloorMapTable__status-color-bar{
	background-color: orange;
}

.FloorMapTable.active .FloorMapTable__status-color-bar {
	bottom: 0;
	left: 0;

	width: 100%;
	height: 14px;

	border-radius: 0 0 8px 8px;
}

.FloorMapTable__sub-text {
	position: absolute;
	top: 40px;
	left: 20px;

	width: calc(100% - 26px);

	white-space: nowrap;
	overflow: hidden;

	color: #888;

	display: block;

	opacity: 0;

	transition: ease all 0.3s;
}

html.dark .FloorMapTable__sub-text {
	color: #666;
}

.FloorMapTable.active .FloorMapTable__sub-text {
	top: 56px;
	left: 26px;
	opacity: 1;
}

.FloorMapTable__tags-scroll-container {
	position: absolute;
	top: 20px;
	left: 0;

	height: 40px;
	opacity: 0;
	transition: ease all 0.3s;
	width: 0;
    cursor: grab;
}


.FloorMapTable.active .FloorMapTable__tags-scroll-container {
	top: 80px;
	opacity: 1;
	width: 100%;
}

.FloorMapTable__tags {

	height: 100%;


	transition: ease all 0.3s;

	display: flex;
	align-items: center;

	padding-left: 26px;
}


.FloorMapTable__image_open_button{
    position: absolute;
    top: 28.5px;
    right:25px;
    border-radius:5px;
    width: 25px;
    height: 25px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: 100ms;
    pointer-events: none;
}

.FloorMapTable__image_open_button.open{
    opacity: 1;
    pointer-events: all;
}

.FloorMapTable__image_open_button:hover .FloorMapTable__image_open_icon{
    filter: brightness(0) invert(0.6);
}

.FloorMapTable__image_open_icon{
    transition: 100ms;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-size: 100%;
    filter: brightness(0) invert(0.5);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNNDUuNiAzMkMyMC40IDMyIDAgNTIuNCAwIDc3LjZWNDM0LjRDMCA0NTkuNiAyMC40IDQ4MCA0NS42IDQ4MGM1LjEgMCAxMC0uOCAxNC43LTIuNEM3NC42IDQ3Mi44IDE3Ny42IDQ0MCAzMjAgNDQwczI0NS40IDMyLjggMjU5LjYgMzcuNmM0LjcgMS42IDkuNyAyLjQgMTQuNyAyLjRjMjUuMiAwIDQ1LjYtMjAuNCA0NS42LTQ1LjZWNzcuNkM2NDAgNTIuNCA2MTkuNiAzMiA1OTQuNCAzMmMtNSAwLTEwIC44LTE0LjcgMi40QzU2NS40IDM5LjIgNDYyLjQgNzIgMzIwIDcyUzc0LjYgMzkuMiA2MC40IDM0LjRDNTUuNiAzMi44IDUwLjcgMzIgNDUuNiAzMnpNOTYgMTYwYTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6bTI3MiAwYzcuOSAwIDE1LjQgMy45IDE5LjggMTAuNUw1MTIuMyAzNTNjNS40IDggNS42IDE4LjQgLjQgMjYuNXMtMTQuNyAxMi4zLTI0LjIgMTAuN0M0NDIuNyAzODIuNCAzODUuMiAzNzYgMzIwIDM3NmMtNjUuNiAwLTEyMy40IDYuNS0xNjkuMyAxNC40Yy05LjggMS43LTE5LjctMi45LTI0LjctMTEuNXMtNC4zLTE5LjQgMS45LTI3LjJMMTk3LjMgMjY1YzQuNi01LjcgMTEuNC05IDE4LjctOXMxNC4yIDMuMyAxOC43IDlsMjYuNCAzMy4xIDg3LTEyNy42YzQuNS02LjYgMTEuOS0xMC41IDE5LjgtMTAuNXoiLz48L3N2Zz4=");
}