.popup_container{
    background-color: white;
    z-index: 11;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    width: 80vw;
    max-width: 700px;
    z-index: 2;
    height: fit-content;
    transform: translateY(30px);
    transition-duration: 100ms;
}

.popup_background.open .popup_container{
    transform: translateY(0px);
}

.popup_background.open{
    opacity: 1;
    pointer-events: all;
}

.popup_background{
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    background-color: #000000aa;
    opacity: 0;
    pointer-events: none;
    transition-duration: 150ms;
}

.popup_header{
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
}

.popup_close{
    cursor: pointer;
    margin:10px;
    margin-top:10px;
    margin-bottom: 10px !important;
}
