.ScaleButtons {
	position: absolute;
	top: 30px;
	right: 30px;

	height: 60px;
	width: 30px;

	border-radius: 4px;

	background-color: white;

	z-index: 10;
	box-shadow: 0 4px 12px 2px rgba(0,0,0,0.1);
	overflow: hidden;
}

html.dark .ScaleButtons {
	background-color: #111;
}

html.mobile .ScaleButtons {
	display: none;
}

.ScaleButtons__button {
	display: block;
	width: 100%;
	height: 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	transition: ease all 0.2s;
}

html.dark .ScaleButtons__button {
	filter: brightness(0) invert(1);
}

.ScaleButtons__button:hover {
	background-color: rgba(0,0,0,0.1);
}
.ScaleButtons__button:active {
	background-color: rgba(0,0,0,0.15);
}

.ScaleButtons__button.top {
	background-image: url("./plus-button.svg");
}

.ScaleButtons__button.bottom {
	background-image: url("./minus-button.svg");
}

.ScaleButtons__divider {
	position: absolute;
	top: 50%;
	width: 100%;
	height: 1px;
	background-color: #ccc;
}

html.dark .ScaleButtons__divider {
	background-color: #333;
}