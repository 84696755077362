.EditableText {
	position: relative;
	display: flex;
	align-items: center;
}

.EditableText__input {
	color: black;
	width: auto;
	background-color: transparent;
	border: none;
	text-align: center;
	font-size: 18px;
	font-family: 'Lato', sans-serif;
}

.EditableText__input::selection {
	color: black;
	background-color: white;
}

html.dark .EditableText__input::selection {
	color: white;
	background-color: black;
}

.EditableText__input:focus {
	outline: none !important;
}

html.dark .EditableText__input {
	color: white;
}

.EditableText::after {
	position: absolute;
	right: -10px;
	width: 12px;
	height: 12px;
	content: "";
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzYyLjcgMTkuM0wzMTQuMyA2Ny43IDQ0NC4zIDE5Ny43bDQ4LjQtNDguNGMyNS0yNSAyNS02NS41IDAtOTAuNUw0NTMuMyAxOS4zYy0yNS0yNS02NS41LTI1LTkwLjUgMHptLTcxIDcxTDU4LjYgMzIzLjVjLTEwLjQgMTAuNC0xOCAyMy4zLTIyLjIgMzcuNEwxIDQ4MS4yQy0xLjUgNDg5LjcgLjggNDk4LjggNyA1MDVzMTUuMyA4LjUgMjMuNyA2LjFsMTIwLjMtMzUuNGMxNC4xLTQuMiAyNy0xMS44IDM3LjQtMjIuMkw0MjEuNyAyMjAuMyAyOTEuNyA5MC4zeiIvPjwvc3ZnPg==");
	filter: brightness(0) invert(0.3);
}