.loader-ring-1{
    animation-delay: 800ms !important;
}

.loader-ring{
    position:absolute;
    border-radius: 500px;
    opacity: 0;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    animation: ringScaleFade 1.6s ease infinite;
}

.loader.light .loader-ring {
    border: 1px solid white;
}

.loader{
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@keyframes ringScaleFade{
    0%{
      transform: scale(0%);
      opacity: 100%;
    }
    100%{
      transform: scale(100%);
      opacity: 0%;
    }
  }


.spin-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #dadada;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.spin-loader::after {
    content: '';  
  box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-bottom-color: #3C8CD6;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 


.spin-loader-small {
    width: 14px;
    height: 14px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spin-loader-small-rotation 1s linear infinite;
}

@keyframes spin-loader-small-rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 