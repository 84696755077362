.errorText{
    font-size: 24px;
    color: #585858;
    margin: 0;
    text-align: center;
    font-family: 'Lexend', sans-serif;
}
.homeText{
    font-size: 24px;
    margin: 5px;
    text-align: center;
    font-family: 'Lexend', sans-serif;
    line-height: normal;
}
.homeText2{
    font-size: 24px;
    margin-left: 40px;
    margin-right: auto;
    text-align: center;
    font-family: 'Lexend', sans-serif;
    line-height: normal;
}
.wrongPageText{
    font-size: 128px;
    margin: 5px;
    text-align: center;
    font-family: 'Lexend', sans-serif;
}

html.mobile .wrongPageText{
    font-size: 64px;
}

.page{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 5px;
}
.otis{
    width:20%;
    height: 20%;
}

.page div{
    justify-content: center;
    align-items: center;
}

.otis{
    width:50vh;
    height: 46vh;
    margin: 20px;
}

html.mobile .otis{
    max-width: 75%;
    min-width: 10%;
    height: auto;
    margin: 20px;
}