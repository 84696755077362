.FloorSelectorButton {
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 2;
	color: white;
}

.FloorSelectorButton__selected-floor {
	position: relative;
	font-size: 18px;
	font-weight: 700;
	margin: 0;
	transition: ease color 0.2s;
	cursor: pointer;
	color: black;
}

html.dark .FloorSelectorButton__selected-floor {
	color: white;
}

.FloorSelectorButton__selected-floor:active {
	color: #999;
}

.FloorSelectorButton__selected-floor::after {
	content: '';
	background-size: cover;
	position: absolute;
	top: 8px;
	right: -16px;
	height: 10px;
	width: 8px;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjQUZBRkFGIi8+Cjwvc3ZnPgoK");
	background-position: center;
	background-repeat: no-repeat;
	transition: ease all 0.2s;
}

.FloorSelectorButton.active .FloorSelectorButton__selected-floor::after {
	transform: rotate(180deg);
}

.FloorSelectorButton__floors-container {
	position: absolute;
	top: 50px;

	background-color: white;
	color: black;
	box-shadow: 0 4px 12px 2px rgba(0,0,0,0.1);

	opacity: 0;
	transform: translateY(-20px) scale(0.9);
	transition: ease all 0.2s;
	border-radius: 5px;

	pointer-events: none;
}

.FloorSelectorButton.active .FloorSelectorButton__floors-container {
	opacity: 1;
	transform: scale(1);
	pointer-events: initial;
}

.FloorSelectorButton__floor {
	display: block;
	padding: 13px 30px;
	white-space: nowrap;
	font-size: 16px;
	border-bottom: 1px solid #ddd;
	transition: ease all 0.2s;
	cursor: pointer;
	text-align: center;
}

.FloorSelectorButton__floor:last-child {
	border-bottom: none;
}

.FloorSelectorButton__floor:hover {
	background-color: rgba(100,100,100,0.05);
}

.FloorSelectorButton__floor:active {
	background-color: rgba(100,100,100,0.15);
}

.FloorSelectorButton__floor.selected {
	background-color: rgba(100,100,100,0.1);
}
