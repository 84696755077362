.table-container{
    position: relative;
    height: 100%;
    width: 100%;
    transition: ease all 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-container::after {
    content:attr(css-size);
    position: absolute;
    top: calc(100% + 12px);
    width: max-content;
    text-align: center;
    background-color: #0e99ff;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    transition: ease all 0.1s;
    opacity: 0;
}

.table.selected .table-container::after {
    opacity: 1;
}


.table.selected .table-container {
    outline: 1px solid #aaa;
    outline-offset: 4px;
    border-radius: 4px;
    transform: scale(1);
    box-shadow: 0 5px 20px 5px rgba(255,255,255,0.1);
    z-index:101;
}

.table-container .table-temp {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: white;
    border-radius: 8px;
}

.map{
    background-color: #444;
    height: 500px;
    width: 500px;
    position: relative;
    overflow: hidden;
}

.table .react-resizable-handle {
    opacity: 0;
    transition: ease opacity 0.2s;
    pointer-events: none;
}

.table.selected .react-resizable-handle {
    opacity: 1;
    pointer-events: initial;
}

.table{
    position: absolute;
   /* left:10px;*/
}

.table::before{
    content:"";
    position:absolute;
    top:-10000px;
    width: 100%;
    height: 20000px;
    left:0;
    pointer-events: none;
    border-left: none;
    border-right: none;
    opacity: 1;
}

.table[css-snap-left="true"]::before{
    border-left:1px solid white !important;
}

.table[css-snap-right="true"]::before{
    border-right:1px solid white !important;
}

.table::after{
    content:"";
    position:absolute;
    top:0;
    width: 20000px;
    height: 100%;
    left:-10000px;
    pointer-events: none;
    border-top: none;
    border-bottom: none;
    opacity: 1;
}

.table[css-snap-top="true"]::after{
    border-top:1px solid white !important;
}

.table[css-snap-bottom="true"]::after{
    border-bottom:1px solid white !important;
}

.tablePreview{
    background-color: white;
    border-radius: 8px;
}

.table_preview{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    cursor: grab;
}

.toolbar{
    width: 500px;
    display: flex;
    padding: 15px;
    background-color: lightgray;
    margin-top: 8px;
    border-radius: 4px;
}