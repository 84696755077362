.leftSection{
    width: 66%;
    border-right: 1px solid #CDCDCD;
    margin-top: 80px; 
    margin-left: 80px;
    display: flex;
    flex-direction: column;

}
html.mobile .rightSection{
    width: 90%;
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    margin-top: 40px;
    border: 0px;
}
html.mobile .leftSection{
    width: 90%;
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    border: 0px;
    margin-top: 0px;
    align-items: center;
    margin-top: 40px;

}
.logoSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 10vh
}
.orgName{
    margin-left: 10px;
    font-size: 30px;
    align-self: center;
    color: #585858;
}
.upperSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

html.mobile .otisBubbles{
    display: none;
}
.buildingBox{
    margin-right: 40px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    height: 35vh;
    color: #585858;
}
.buildingImage{
    width: 50%;
    height: 100%;
    object-fit: cover;
}
html.mobile .buildingImage{
    width:100%;
    height: 50%
}
.buildingText{
    justify-content: space-around;
    font-size: 24px;
    font-family: 'Lexend', sans-serif;
    display: flex;
    flex-direction: column;
}
html.mobile .buildingText{
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    display: inline;

}
.rightSection{
    margin-top: 80px;
    margin-right: 40px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 34%;
    max-height: 80vh;
}
.buildingsSection{
    max-height: 75vh;
}
html.mobile .buildingsSection{
    height: 28vh;
}
.building{
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 40px;
    margin-left: 5px;
    border-radius: 4px;
    height: 150px;
    width: 98%;
    position:relative;
    flex-direction: row;
    z-index: 0;
    justify-content: space-around;
    align-items: center;
    color: #585858;
}
.building::after{
    content:"";
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:50px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.building.red::after{
    background-color: #EB0B1C;
}
.building.orange::after{
    background-color: #FFC93E;
}

.building.green::after{
    background-color: #60E25D;
}
.buildingInfo{
    flex-direction: column;
    display: flex;
    margin-left: 20px;
    justify-content: space-around;
    height: 100%;

}

.buildingButton{
    font-size: 30px;

}
.buildingAngle{
    justify-self: center;
}
.buildingSearch{
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0px;
    width: 100%;
    border: 1px solid #CDCDCD;
    font-size: 18px;
    padding-left: 10px;
    
    
}
.main{
    background-color: white;
    display: flex;
    flex-direction:row;
    justify-content:inherit;
}
html.mobile .main{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    height: 10vh;
}
html.mobile .buildingBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    margin-top: 10vh;
    height: 30vh;
    margin-right: 0px;
    width: 100%;
}
html.mobile .noHover{
    position: relative;
    height: 30vh;
    margin-top: 10vh;
}
.mapButton,.locationButton,.building{
    cursor: pointer;
}
.map-container {
    height: 100%;
    width: 100%;
}
.mapThing {
    height: 50vh;
    width: 100%;
}
html.mobile .mapThing {
    margin-left: 0px;
}
html.mobile .popup-content{
    width: 80%
}
input::placeholder{
    color:#585858;
    font-family: 'Lexend', sans-serif;
}
.searchSection{
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 75%;
    position: relative;
}
.locationButton{
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #CDCDCD;
    border-left: 0px;
    aspect-ratio: 1 / 1;
}
.locationCrosshair{
    color:#585858;
    font-size: 18px;
}
.buildingSearchFilters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #CDCDCD;
}

html.mobile .buildingInfo{
    margin-left: 35px;

}
html.mobile .tablesFree, html.mobile .siteName{
    font-size: 24px;
}
.siteName{
    font-size: 30px;
    font-family: 'Lexend', sans-serif;
}
.siteDistance{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
}
.tablesFree{
    font-family: 'Lexend', sans-serif;
    font-size: 30px;
}
.siteMetric{
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}
html.mobile .siteMetric{
    margin: 0px;

}
.metricIcon{
    margin: auto
}
.siteDistance{
    margin-top: 5px;
}
.noHover{
    font-size: 30px;
    font-family: 'Lexend', sans-serif;
    margin-top: 50vh;
    align-self: center;
    position: absolute;
    color: #585858;
}
.iconDiv{
    height: 30px;
    text-align: center;
    aspect-ratio: 1/1;
    margin-right: 5px;
}
.mapButton{
    height: 50px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #CDCDCD;
    aspect-ratio: 1/1;
    color: #585858;
    z-index: 1;
}