.FloorMapTableTag {
	display: inline-block;

	padding: 2px 12px 2px 30px;

	border-radius: 200px;
	border: 1px solid #ccc;

	color: #888;

	margin-right: 8px;

    white-space: nowrap;
    position: relative;
    font-size: 12px;
}

html.dark .FloorMapTableTag {
	color: #666;
	border: 1px solid #2a2a2a;
}

.FloorMapTableTag:last-child:after {
	content: "";
	width: 28px;
	height: 100%;
	position: absolute;
	left: 100%;
}

.FloorMapTableTag::before {
	content: '';
	background-size: cover;
	position: absolute;
	top: 6px;
	left: 12px;
	height: 12px;
	width: 12px;

}

.FloorMapTableTag.disabled::before {
	filter: brightness(0) invert(0.5);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMTkyIDk2YTQ4IDQ4IDAgMSAwIDAtOTYgNDggNDggMCAxIDAgMCA5NnpNMTIwLjUgMjQ3LjJjMTIuNC00LjcgMTguNy0xOC41IDE0LTMwLjlzLTE4LjUtMTguNy0zMC45LTE0QzQzLjEgMjI1LjEgMCAyODMuNSAwIDM1MmMwIDg4LjQgNzEuNiAxNjAgMTYwIDE2MGM2MS4yIDAgMTE0LjMtMzQuMyAxNDEuMi04NC43YzYuMi0xMS43IDEuOC0yNi4yLTkuOS0zMi41cy0yNi4yLTEuOC0zMi41IDkuOUMyNDAgNDQwIDIwMi44IDQ2NCAxNjAgNDY0Qzk4LjEgNDY0IDQ4IDQxMy45IDQ4IDM1MmMwLTQ3LjkgMzAuMS04OC44IDcyLjUtMTA0Ljh6TTI1OS44IDE3NmwtMS45LTkuN2MtNC41LTIyLjMtMjQtMzguMy00Ni44LTM4LjNjLTMwLjEgMC01Mi43IDI3LjUtNDYuOCA1N2wyMy4xIDExNS41YzYgMjkuOSAzMi4yIDUxLjQgNjIuOCA1MS40aDUuMWMuNCAwIC44IDAgMS4zIDBoOTQuMWM2LjcgMCAxMi42IDQuMSAxNSAxMC40TDQwMiA0NTkuMmM2IDE2LjEgMjMuOCAyNC42IDQwLjEgMTkuMWw0OC0xNmMxNi44LTUuNiAyNS44LTIzLjcgMjAuMi00MC41cy0yMy43LTI1LjgtNDAuNS0yMC4ybC0xOC43IDYuMi0yNS41LTY4Yy0xMS43LTMxLjItNDEuNi01MS45LTc0LjktNTEuOUgyODIuMmwtOS42LTQ4SDMzNmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkgyNTkuOHoiLz48L3N2Zz4=");
	
	background-position: center;
	background-repeat: no-repeat;
}

.FloorMapTableTag.computer::before {
	filter: brightness(0) invert(0.5);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzg0IDk2VjMyMEg2NEw2NCA5NkgzODR6TTY0IDMyQzI4LjcgMzIgMCA2MC43IDAgOTZWMzIwYzAgMzUuMyAyOC43IDY0IDY0IDY0SDE4MS4zbC0xMC43IDMySDk2Yy0xNy43IDAtMzIgMTQuMy0zMiAzMnMxNC4zIDMyIDMyIDMySDM1MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkgyNzcuM2wtMTAuNy0zMkgzODRjMzUuMyAwIDY0LTI4LjcgNjQtNjRWOTZjMC0zNS4zLTI4LjctNjQtNjQtNjRINjR6bTQ2NCAwYy0yNi41IDAtNDggMjEuNS00OCA0OFY0MzJjMCAyNi41IDIxLjUgNDggNDggNDhoNjRjMjYuNSAwIDQ4LTIxLjUgNDgtNDhWODBjMC0yNi41LTIxLjUtNDgtNDgtNDhINTI4em0xNiA2NGgzMmM4LjggMCAxNiA3LjIgMTYgMTZzLTcuMiAxNi0xNiAxNkg1NDRjLTguOCAwLTE2LTcuMi0xNi0xNnM3LjItMTYgMTYtMTZ6bS0xNiA4MGMwLTguOCA3LjItMTYgMTYtMTZoMzJjOC44IDAgMTYgNy4yIDE2IDE2cy03LjIgMTYtMTYgMTZINTQ0Yy04LjggMC0xNi03LjItMTYtMTZ6bTMyIDE2MGEzMiAzMiAwIDEgMSAwIDY0IDMyIDMyIDAgMSAxIDAtNjR6Ii8+PC9zdmc+");

	background-position: center;
	background-repeat: no-repeat;
	height: 10px;
	top: 7px;
}

.FloorMapTableTag.printer::before {
	filter: brightness(0) invert(0.5);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMTI4IDBDOTIuNyAwIDY0IDI4LjcgNjQgNjR2OTZoNjRWNjRIMzU0LjdMMzg0IDkzLjNWMTYwaDY0VjkzLjNjMC0xNy02LjctMzMuMy0xOC43LTQ1LjNMNDAwIDE4LjdDMzg4IDYuNyAzNzEuNyAwIDM1NC43IDBIMTI4ek0zODQgMzUydjMyIDY0SDEyOFYzODQgMzY4IDM1MkgzODR6bTY0IDMyaDMyYzE3LjcgMCAzMi0xNC4zIDMyLTMyVjI1NmMwLTM1LjMtMjguNy02NC02NC02NEg2NGMtMzUuMyAwLTY0IDI4LjctNjQgNjR2OTZjMCAxNy43IDE0LjMgMzIgMzIgMzJINjR2NjRjMCAzNS4zIDI4LjcgNjQgNjQgNjRIMzg0YzM1LjMgMCA2NC0yOC43IDY0LTY0VjM4NHpNNDMyIDI0OGEyNCAyNCAwIDEgMSAwIDQ4IDI0IDI0IDAgMSAxIDAtNDh6Ii8+PC9zdmc+");

	background-position: center;
	background-repeat: no-repeat;
}