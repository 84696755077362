.FloorPlanEditor__TextInput {
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 0 5px 5px rgba(0,0,0,0.01);
	border-radius: 5px;
	width: 100%;
}

html.dark .FloorPlanEditor__TextInput {

	background-color: #161616;
	box-shadow: 0 0 5px 5px rgba(0,0,0,0.05);
}

.FloorPlanEditor__TextInput__label {
	margin: 0;
	font-size: 12px;
	color: #777;
	margin-left: 10px;
	margin-bottom: 2px;
}

.FloorPlanEditor__TextInput__input {
	color: black;
	border: none;
	padding: 5px 7px;
	font-size: 12px;
	background-color: transparent;
	width: 20px;
	flex: 1;
}

html.dark .FloorPlanEditor__TextInput__input {
	color: white;
}

.FloorPlanEditor__TextInput__input:focus {
    outline: none !important;
}
