.TableSearchItem {
	position: relative;
	height: 48px;
	display: flex;
	align-items: center;
	border-top: 1px solid #ddd;
	cursor: pointer;
}

html.dark .TableSearchItem {
	border-top: 1px solid #222;
}

.TableSearchItem__title {
	position: absolute;
	left: 24px;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
}

.TableSearchItem__availability {
	position: absolute;
	right: 20px;
	margin: 0;
	font-size: 12px;
	color: #808080;
}

.TableSearchItem__color-bar {
	position: absolute;
	left: 0;
	height: calc(100% + 2px);
	width: 6px;

	background-color: #C90000;
}

.TableSearchItem.available .TableSearchItem__color-bar {
	background-color: #60C21D;
}

.TableSearchItem.available.obfuscated .TableSearchItem__color-bar {
	background-color: orange;
}