.RefreshButton {
	position: fixed;
	bottom: 30px;
	left: 30px;
	height: 26px;
	width: 132px;
	z-index: 2;
	cursor: pointer;
}

html.mobile .RefreshButton {
	display: none;
}

.RefreshButton__container {
	position: absolute;
	left: 0;
	height: 26px;
	width: 132px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(30,30,30,0.9);
	border-radius: 4px;
	transition: ease all 0.25s;
	box-shadow: 0 4px 12px 2px rgba(0,0,0,0.1);
	overflow: hidden;
}



.RefreshButton.disconnected .RefreshButton__container {
	background-color: rgba(203,26,16,1);
	width: 110px;
}
.RefreshButton:hover .RefreshButton__container {
	background-color: #eee;
	width: 70px;
}

.RefreshButton.updating .RefreshButton__container {
	background-color: rgba(30,30,30,0.9);
	width: 26px;
}

.RefreshButton__last-updated-text {
	font-size: 13px;
	color: #ccc;
	transition: ease all 0.25s;
	white-space: nowrap;
	margin: 0;
}

.RefreshButton:hover .RefreshButton__last-updated-text {
	opacity: 0;
}

.RefreshButton.updating .RefreshButton__last-updated-text {
	opacity: 0;
}

.RefreshButton.disconnected .RefreshButton__last-updated-text {
	opacity: 0;
}

.RefreshButton__refresh-text {
	position: absolute;
	font-size: 13px;
	color: black;
	opacity: 0;
	transition: ease all 0.25s;
	white-space: nowrap;
	margin: 0;
}

.RefreshButton:hover .RefreshButton__refresh-text {
	opacity: 1;
}

.RefreshButton.updating .RefreshButton__refresh-text {
	opacity: 0;
}


.RefreshButton__loader {
	position: absolute;
	opacity: 0;
	transition: ease all 0.2s;
}

.RefreshButton.updating .RefreshButton__loader {
	opacity: 1;
}

.RefreshButton__disconnected-text {
	position: absolute;
	font-size: 13px;
	color: white;
	opacity: 0;
	transition: ease all 0.25s;
	white-space: nowrap;
	margin: 0;
}

.RefreshButton.disconnected .RefreshButton__disconnected-text {
	opacity: 1;
}

.RefreshButton:hover .RefreshButton__disconnected-text {
	opacity: 0;
}