html.mobile .FloorSummaryHeader {
	display: flex;
}

.FloorSummaryHeader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(44px + env(safe-area-inset-top));
	padding-top: env(safe-area-inset-top);
	background-color: white;
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
}

html.dark .FloorSummaryHeader {
	background-color: #111;
	color: white;
}

.FloorSummaryHeader__refresh {
	display: block;
	position: absolute;
	right: 4px;
	height: 44px;
	width: 44px;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00NjMuNTI1IDIyMy4wMzhINDcyLjAyNUM0ODUuMzI1IDIyMy4wMzggNDk2LjAyNSAyMTIuMzM4IDQ5Ni4wMjUgMTk5LjAzOFY3MS4wMzg0QzQ5Ni4wMjUgNjEuMzM4NCA0OTAuMjI1IDUyLjUzODQgNDgxLjIyNSA0OC44Mzg0QzQ3Mi4yMjUgNDUuMTM4NCA0NjEuOTI1IDQ3LjEzODQgNDU1LjAyNSA1NC4wMzg0TDQxMy40MjUgOTUuNjM4NEMzMjUuODI1IDkuMTM4NDEgMTg0LjcyNSA5LjQzODQyIDk3LjYyNSA5Ni42Mzg0QzEwLjEyNSAxODQuMTM4IDEwLjEyNSAzMjUuOTM4IDk3LjYyNSA0MTMuNDM4QzE4NS4xMjUgNTAwLjkzOCAzMjYuOTI1IDUwMC45MzggNDE0LjQyNSA0MTMuNDM4QzQyNi45MjUgNDAwLjkzOCA0MjYuOTI1IDM4MC42MzggNDE0LjQyNSAzNjguMTM4QzQwMS45MjUgMzU1LjYzOCAzODEuNjI1IDM1NS42MzggMzY5LjEyNSAzNjguMTM4QzMwNi42MjUgNDMwLjYzOCAyMDUuMzI1IDQzMC42MzggMTQyLjgyNSAzNjguMTM4QzgwLjMyNSAzMDUuNjM4IDgwLjMyNSAyMDQuMzM4IDE0Mi44MjUgMTQxLjgzOEMyMDUuMDI1IDc5LjYzODQgMzA1LjUyNSA3OS4zMzg0IDM2OC4xMjUgMTQwLjgzOEwzMjcuMDI1IDE4Mi4wMzhDMzIwLjEyNSAxODguOTM4IDMxOC4xMjUgMTk5LjIzOCAzMjEuODI1IDIwOC4yMzhDMzI1LjUyNSAyMTcuMjM4IDMzNC4zMjUgMjIzLjAzOCAzNDQuMDI1IDIyMy4wMzhINDYzLjUyNVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
	background-size: 16px 16px;
	background-position: center;
	background-repeat: no-repeat;
	filter: brightness(0) invert(0.5);
	transition: ease filter 0.2s;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.FloorSummaryHeader__refresh.active {
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zOTIuNTI1IDEyMEMzNTcuNzIxIDg0LjgwNTggMzA5LjQwNyA2MyAyNTYgNjNDMTQ5Ljk2MSA2MyA2NCAxNDguOTYxIDY0IDI1NUM2NCAzNjEuMDM5IDE0OS45NjEgNDQ3IDI1NiA0NDdDMzA5LjA3NCA0NDcgMzU3LjExOSA0MjUuNDY1IDM5MS44NzIgMzkwLjY1NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSI2NCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
	background-size: 16px 16px;
	background-position: center;
	background-repeat: no-repeat;

    animation: spin 1s linear infinite;
	filter: none;
}

html.dark .FloorSummaryHeader__refresh.active {
	filter: brightness(0) invert(1);
}

.FloorSummaryHeader__refresh.disconnected {
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjI1Ni41IiBjeT0iMjU0LjUiIHI9IjIyMi41IiBmaWxsPSIjRTcwMDAwIi8+CjxjaXJjbGUgY3g9IjI1NyIgY3k9IjM2MCIgcj0iMjYiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0yMTQuNjIxIDE3MS42NzRDMjExLjEyMiAxNDYuNDc4IDIzMC42OTQgMTI0IDI1Ni4xMzIgMTI0VjEyNEMyODEuNDc4IDEyNCAzMDEuMDIzIDE0Ni4zMjQgMjk3LjY3NCAxNzEuNDQ4TDI4My4xMTQgMjgwLjY0MkMyODEuMzMyIDI5NC4wMTQgMjY5LjkyNSAzMDQgMjU2LjQzNSAzMDRWMzA0QzI0My4wMDEgMzA0IDIzMS42MjQgMjk0LjA5NCAyMjkuNzc2IDI4MC43ODhMMjE0LjYyMSAxNzEuNjc0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
	background-size: 22px 22px;
	background-position: center;
	background-repeat: no-repeat;
}
.FloorSummaryHeader__refresh.disconnected {
	filter: none
}

.FloorSummaryHeader__back-button {
	position: absolute;
	left: 28px;
	margin: 0;
	color: #888;
	font-size: 16px;
	height: 44px;
	line-height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.FloorSummaryHeader__back-button:link {
	color: #888;
}

.FloorSummaryHeader__back-button::after {
	content: '';
	background-size: cover;
	position: absolute;
	left: -15px;
	height: 13px;
	width: 10px;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01IDFMMSA1TDUgOSIgc3Ryb2tlPSIjODA4MDgwIi8+Cjwvc3ZnPgo=");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

