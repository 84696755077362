.loginPageContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginPageHeader{
    display: flex;
    flex-direction: column;
    margin: 10px 10px 10px 10px;
    align-items: center;
}

.loginForm{
    display: flex;
    flex-direction: column;
    margin: 10px 10px 10px 10px;
    align-items: center;
}

.loginContentBox{
    border-style: solid;
    border-width: 1px;
    width: 70%;
    height: 100%;
    border-radius: 4px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px 30px 30px 30px;

}

.loginContentBox h1{
    font-family: 'Lexend';
    font-size: 30pt;
}

.loginContentBox h2{
    font-family: 'Lexend';
    font-size: 24pt;
}

.loginContentBox p{
    font-family: 'Inter';
    font-size: 18pt;
    color: #585858;
}

.inputSection{
    margin: 10px 10px 10px 10px;
}

.inputField{
    border-radius: 4px;
    cursor: pointer;
}

.loginFormButtonSection{
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.submitButton{
    background-color: aqua;
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}

.adminButton{
    background-color: gray;
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}

.forgotPasswordText{
    display: flex;
    align-self: center;
    cursor: pointer;
    background: none;
    border: none;
    color: blue;
    font-style: italic;
}

.loginButton{
    margin-top: 20px;
    border-radius: 4px;
    background-color: #3C8CD6;
    color: white;
    border-width: 2px;
    border-color: #585858;
    cursor: pointer;
    font-family: 'Lexend';
    font-size: 18pt;
    padding: 10px 10px 10px 10px;
}

.loginButton:hover{
    margin-top: 20px;
    border-radius: 4px;
    background-color: #256CAE;
    color: white;
    border-width: 2px;
    border-color: #585858;
    cursor: pointer;
    font-family: 'Lexend';
    font-size: 18pt;
    padding: 10px 10px 10px 10px;
}

hr{
    border-width: 1px;
    border-style: solid;
    width: 500px;
}

.backContainerDesktop{
    display: flex;
    color:#585858;
    margin-right: 90%;
    cursor: pointer;
}

.backArrowDesktop{
    font-size: 25px;
}

.backTextDesktop{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    margin-left: 10px;
}

.loginHeroImg{
    width: 20%;
    height: 20%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


