.FloorPlanEditor__360ImageSection {
	
}

.FloorPlanEditor__360ImageSection__container {
	position: relative;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
}

.FloorPlanEditor__360ImageSection__image {
	position: absolute;
	left: 30px;

	height: 50px;
	width: 50px;

	background-color: #ccc;
	border-radius: 5px;

	display: block;
}

html.dark .FloorPlanEditor__360ImageSection__image {
	background-color: #111;
}


.FloorPlanEditor__360ImageSection__select-image-button {
	appearance: unset;
	position: absolute;
	right: 30px;
	background-color: #ddd;
	color: #222;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	font-size: 13px;
	cursor: pointer;
	transition: ease all 0.2s;
}

html.dark .FloorPlanEditor__360ImageSection__select-image-button {
	background-color: #333;
	color: #ddd;
}

.FloorPlanEditor__360ImageSection__select-image-button:hover {
	transform: scale(1.04);
	background-color: #ccc;

}

html.dark .FloorPlanEditor__360ImageSection__select-image-button:hover {

	background-color: #383838;
}