.FloorPlanEditor__TablesSection {
	min-height: 0;
	flex: 1;
}

.FloorPlanEditor__TablesSection__container {
	overflow-y: auto;
	height: 100%;
}

.FloorPlanEditor__TablesSection__table {
	display: block;
	width: 100%;
	padding: 5px 18px;
	transition: ease all 0.2s;
	font-size: 12px;
	cursor: pointer;
}

.FloorPlanEditor__TablesSection__table.selected {

	background-color: rgba(100,100,100,0.1);
}

.FloorPlanEditor__TablesSection__table:hover {
	background-color: rgba(100,100,100,0.1);
}
