.BuildingSummary {
    padding-bottom: env(safe-area-inset-bottom);
}

html.mobile .BuildingSummary {
    width: 100vw;
    overflow: hidden;
}

.fullscreen{
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroContainer{
    width: 100vw;
    height: 230px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-arrow-mobile{
    color:#FFFFFF;
    position: absolute;
    top: calc(26px + env(safe-area-inset-top));
    left:26px;
    cursor: pointer;
}

.back-arrow-mobile>svg{
    font-size: 30px;
}


.heroImg{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.heroContainer::before{
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 56%;
}

.heroContainer.light::before{
    opacity: 38%;
}

.buildingName{
    position: absolute;
    bottom:40px;
    left:50px;
    padding:0;
    margin:0;
    color: white;
    font-size: 50px;
    font-family: 'Lexend', sans-serif;
    font-weight: normal;
}

.buildingName.mobile{
    left:auto;
    bottom: 50px;
}

.editButton{
    border-radius: 4px;
    border-width: 1px;
    font-size: 16px;
    font-family: 'Lexend', sans-serif;
}

.buildingEditButton{
    position: absolute;
    bottom:5px;
    left:50px;
    padding:0;
    margin:0;
    color: white;
    background-color: #3C8CD6;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-size: 20px;
    font-family: 'Lexend', sans-serif;
    font-weight: normal;
    cursor: pointer;
}

.buildingEditButton:hover{
    background-color: #256CAE;
}

.buildingEditButtonMobile{
    position: absolute;
    bottom:5px;
    padding:0;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: #3C8CD6;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-size: 20px;
    font-family: 'Lexend', sans-serif;
    font-weight: normal;
    cursor: pointer;
}

.buildingEditButtonMobile:hover{
    background-color: #256CAE;
}


.buildingEditButton.mobile{
    margin-left:auto;
    margin-right: auto;
    left: auto;
}

.buildingImageEditButton{
    position: absolute;
    bottom:70%;
    height: fit-content;
    left:65%;
    padding:0;
    color: white;
    background-color: #3C8CD6;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    font-size: 20px;
    font-family: 'Lexend', sans-serif;
    font-weight: normal;
    cursor: pointer;    
}

.buildingImageEditButton:hover{
    background-color: #256CAE;
}

.buildingNamePopup{
    border-radius: 4px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 24px;
    font-family: 'Lexend', sans-serif;
    font-weight: normal;
    background-color: white;
    padding: 15px 15px 15px 15px;
}

.buildingNamePopupFieldSection{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.buildingNamePopupFieldSection label{
    margin-bottom: 5px;
}

.buildingNamePopupFieldSection input{
    margin-bottom: 5px;
}

.buildingNamePopupButtonSection{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: left;
    margin-right: 50px;
}

.buildingNamePopupButtonSection button{
    margin-right: 10px;
}

/*not used*/
.backgroundPopupOverlay{
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 100%;
    padding-left: 100%;
    padding-right: 100%;
    padding-bottom: 100%;
    background-color: grey;
    position: absolute;
}

/*not used*/
.backgroundPopupOverlay .testPopup{
    width: max-content;
    position: relative;
    bottom: 100px;
    height: 100%;
    z-index: 1;
}

.editForm{
    display: flex;
    flex-direction: column;
}

.editForm form{
    display: flex;
    flex-direction: column;
}

.formButtonContainer{
    display: flex;
    flex-direction: row;
}

.formButtonContainer .interactButton{
    font-family: 'Lexend';
    margin-right: 20px;
}

.formButtonContainer .adminButton{
    font-family: 'Lexend';
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    border-width: 2px;
    margin-bottom: 20px;
    height: fit-content;
}

.popup .popupOverlay{
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    padding: 50px 50px 75px 50px;
    font-size: 24px;
    bottom: 5%;
    overflow-y: auto;
    width: 50vw;
    min-width: 300px;
    height: auto;
    max-height: 800px;
}

.popupOverlay input{
    font-family: 'Inter';
    font-size: 18pt;
    margin-bottom: 30px;
}

.popupOverlay .descriptionContainer{
    width:100%;
}

.popupOverlay .buildingDescription{
    height: 200px;
}

.popupOverlay label{
    font-family: 'Lexend';
    font-size: 24pt;
    margin-bottom: 5%;
}

.buttonContainer{
    display: flex;
    flex-direction: row !important;
}

.buttonContainer .adminButton{
    border-width: 2px;
}

.imageUploadContainer{
    display: flex;
}

.imageUploadContainer img{
    display: flex;
    margin-right: auto;
    border-style: solid;
    border-width: 4px;
    height: 300px;
    width: 300px;
}

.popupOverlay .buttonContainer button{
    margin-right: 10px;
}

.popupOverlay h1{
    font-family: 'Lexend';
    font-size: 30pt;
    margin-bottom: 40px;
}

.editFloorPopup{
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 15px 15px 15px 15px;
    font-size: 24px;
}

.editFloorPopup input{
    margin-bottom: 10px;
}

.editFloorPopup button{
    margin-right: 5px;
}

.popup {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: calc(100%*2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:default;
    z-index: 1;
}
  
/* Not used*/
.popup .popuptext {
    display: flex;
    visibility: visible;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
}
  
/* Not used */
.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.heroOccupancySection{
    height: 230px;
    width: 230px;
    background-color: white;
    position: absolute;
    right: 0;
    opacity: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

html.dark .heroOccupancySection {
    background-color: #111;
}


.occupancyValue{
    font-family: 'Inter', sans-serif;
    font-size: 45px;
    margin: 0;
    margin-left:8px;
    margin-right:5px;
}

html.dark .occupancyValue {
    color: #f0f0f0;
}

.occupancyFullText{
    margin: 0;
    color: #585858;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
}


main{
    width: 100%;
}

main.desktop{
    display: flex;
    flex-direction: row;
}

main.mobile{
    padding:20px;
    /* width: calc(100% - 40px); */
    position: relative;
    background-color: #fff;
}

html.dark main.mobile {
    background-color: #111;
}

.mobileOccupancyText{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top:45px;
    margin-bottom:45px;
}

.desktopLeftSection{
    width: 70%;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.desktopRightSection{
    width:30%;
    padding: 30px;
    padding-top: 40px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.desktopRightSection::after{
    content:"";
    position: absolute;
    left:0;
    height: calc(100% - 60px);
    top: 30px;
    border-left:1px solid #CDCDCD;
}

.backContainerDesktop{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#585858;
    margin-bottom: 20px;
}

.backArrowDesktop{
    font-size: 25px;
}

.backTextDesktop{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    margin-left: 10px;
}

@media (prefers-color-scheme: dark) {
    .backTextDesktop {
        color: white;
    }

    .backArrowDesktop svg {
        color: white;
    }

    .desktopRightSection::after{
        border-left:1px solid #333;
    }

    hr.buildingSummary {
        background-color: #333;
    }
}

.title{
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin:0;
    margin-top: 20px;
}

html.dark .title {
    color: white;
}

.title.mobile{
    width: 100%;
    text-align: center;
}

.floorsSubtitle{
    margin:0;
    margin-top: 3px;
    color: #585858;
    margin-bottom: 25px;
}

.floorSectionContainer{
    width: 100%;
}

.floorsSubtitle.mobile{
    width: 100%;
    text-align: center;
}

.floorRow .interactButton{
    margin-top: 0 !important;
    font-size: 10pt;
    background: none;
    color: black;
    padding: 5px 5px 5px 5px;
}

.floorRow .interactButton:hover{
    margin-top: 0 !important;
    font-size: 10pt;
    background: lightgray;
    color: black;
}

.floorRow{
    padding:15px 25px;
    padding-left: 45px;
    border-radius: 4px;
    margin-bottom: 20px;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

html.dark .floorRow {
    background-color: #222;
}

.floorRow::after{
    content:"";
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width:20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.floorRow.red::after{
    background-color: #EB0B1C;
}

.floorRow.orange::after{
    background-color: #FFC93E;
}

.floorRow.green::after{
    background-color: #60E25D;
}

.shadow{
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.5);
}

.floorRowName{
    margin:0;
    font-size: 16px;
    color: #585858;
}

html.dark .floorRowName {
    color: #fff;
}

.floorRowSubText{
    color:#585858;
    font-size: 14px;
    margin: 0;
}

html.dark .floorRowSubText {
    color: #777;
}

.selectRowBtn{
    font-size: 16px;
    color: #585858;
}

hr.buildingSummary{
    width: 100%;
    background-color:#CDCDCD;
    margin-top:30px;
    margin-bottom: 30px;
    border: none;
    height:1px;
}

.infoSectionContainer{
    display: flex;
    flex-direction: column;
}

.infoSectionRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.infoSectionRow button{
    margin-left: 10px;
}

.infoIcon{
    color: #585858;
    font-size: 20px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
}

.infoText{
    font-size: 18px;
    color: #585858;
    margin: 0;
}

html.dark .infoText {
    color: #cacaca;
    font-size: 14px;
}

.blueBtn{
    cursor: pointer;
    background-color: #3C8CD6;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 11px;
    font-size: 18px;
}

.directionBtn.mobile{
    margin: auto;
}

.barChartContainer{
    position: relative;
    display:flex; 
    width:fit-content;
    align-items: flex-end;
    border-top: 2px dashed #CDCDCD;
    height: fit-content;
}

html.dark .barChartContainer {

    border-top: 2px dashed #333;
}

.occupancyGraph{
    position: relative;
    width: fit-content;
    min-width: 100px;
    min-height: 50px;
    width: 100%;
    display: flex;
}

.occupancyGraph.mobile{
    min-width: 0;
    justify-content: center;
}

.graphMaxVal{
    position:absolute;
    right:30px;
    transform: translateX(114%) translateY(-50%);
    color: #585858;
    font-family: 'Inter', sans-serif;
}

html.dark .graphMaxVal {
    color: #777;
}

.barChartBar{
    background-color:#3C8CD6;
    width:30px; 
    margin-right:5px; 
    margin-left:5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
}

.barChartBar.now{
    opacity: 67%;
}

.barChartLine{
    width: 100%;
    border-bottom: 2px solid #CDCDCD;
}

html.dark .barChartLine {
    border-bottom: 2px solid #333;
}

.barChartTime{
    color: #585858;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

html.dark .barChartTime {
    color: #777;
}

.barChartBarContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popularTimesContainer.mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputTitle{
    width: 150px;
}

.editTimesSectionContainer{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top:5vh;
    align-items: center;
}

.editTimesSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.editTimesSegment{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.editTimesDaySelect{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
}

.editTimesCurrentTimesText{
    margin-left: 10px;
    margin-right: auto;
}

.buildingAdminNavBar{
    display: flex;
    flex-direction: row;
    max-width: 90vw;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: rgba(131, 132, 133, 0.0);
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.buildingAdminNavBarButton{
    color: #585858;
    background: none;
    border-width: 0;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
}

.buildingAdminNavBarButton:hover{
    color: black;
    cursor: pointer;
}

.buildingAdminNavBarButtonDivider{
    color: #CDCDCD;
    background: none;
    border-width: 0;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
}

.buildingAdminNavBarButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background: none;
    border-width: 0;
    width: 50vh;
}

.closedCheckbox{
    margin-right: auto;
    width: 50%;
}

.checkboxContainer{
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}

.adminWarningBox{
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    background-color: white;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    font-size: 18px;
    font-family: 'Lexend';
    border-color: #CDCDCD;
    animation-name: "";
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
}

@keyframes example {
    0%   {background-color:red; left:0px; top:0px;}
    25%  {background-color:yellow; left:200px; top:0px;}
    50%  {background-color:blue; left:200px; top:200px;}
    75%  {background-color:green; left:0px; top:200px;}
    100% {background-color:red; left:0px; top:0px;}
}

@keyframes fadeIn{
    0% {margin-top: 1vh;}
    25% {margin-top: 2vh;}
    50% {margin-top: 3vh;}
    75% {margin-top: 4vh;}
    100% {margin-top: 5vh;}
}

@keyframes fadeOut{
    0% {margin-top: 5vh;}
    25% {margin-top: 4vh;}
    50% {margin-top: 3vh;}
    75% {margin-top: 2vh;}
    100% {margin-top: 1vh;}
}

@keyframes bounce{
    0% {margin-top: 5vh;background-color:red;}
    10% {margin-top: 4vh;}
    20% {margin-top: 3vh;}
    30% {margin-top: 4vh;}
    40% {margin-top: 5vh;background-color:yellow;}
    50% {margin-top: 4vh;}
    60% {margin-top: 3vh;}
    70% {margin-top: 4vh;}
    80% {margin-top: 5vh;background-color:blue;}
    90% {margin-top: 4vh;}
    100% {margin-top: 3vh;}
}
  

.adminWarningBox button{
    margin-left: auto;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.adminWarningBoxContent{
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-around;
}

.adminWarningBoxContent p{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    color: #585858;
    width: 60%;
}

.adminWarningBoxContentButtonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: auto;
}

.redBtn{
    background-color: #EB0B1C;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 11px;
    font-size: 18px;
}

.nakedAdminViewButtonContainer{
    margin-right: auto;
    margin-left: 10px;
    margin-top: 10px;
}

.accountTabContainer{
    width: 100vw;
    padding-bottom: 10px;
    padding-top: 10px;
}

.accountTabContent{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 10px;
}

.accountTabContent h1{
    margin-left: auto;
    margin-right: 10px;
}

.accountTabContent button{
    width: 8vw;
    margin-left: 10px;
    margin-right: auto;
}


.warningBox{
    padding:15px 25px;
    border-radius: 4px;
    margin-bottom: 20px;
    position:relative;
    background-color: rgb(255, 244, 230);
    font-family:  'Lexend', sans-serif;;
}

.warningBox > h3{
    color: rgb(253, 126, 20);
    margin-bottom: 10px;
    font-size: 1.05rem;
}

.scroll-container {
    cursor: grab !important;
    width: calc(100% - 30px);
}

html.mobile .scroll-container {
    max-width: 280px;
}
