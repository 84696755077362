.TableSearch {
	position: fixed;
	top: 30px;
	left: 30px;
	width: 400px;
	height: 106px;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 4px 12px 2px rgba(0,0,0,0.1);
	z-index: 6;
	cursor: pointer;
}

.TableSearch.active {
	cursor: initial;
}

html.mobile .TableSearch {
	top: calc(calc(100% - 50px) - env(safe-area-inset-bottom));
	left: 0;
	width: 100%;
	height: calc(240px + env(safe-area-inset-bottom));
	padding-bottom: env(safe-area-inset-bottom);
	display: flex;
	align-items: center;
	border-radius: 0;
	transition: ease all 0.3s;
}

html.mobile .TableSearch.active {
	top: calc(100% - calc(240px + env(safe-area-inset-bottom)));
}

html.dark .TableSearch {
	background-color: #111;
	color: white;
}


.TableSearch__search-input {
	position: absolute;
	bottom: 20px;
	left: 20px;
	right: 20px;
	background-color: #eee;
	border: none;
	padding: 6px 13px;
	font-size: 14px;
	border-radius: 4px;

   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.TableSearch__search-input:focus {
    outline: none !important;
}

html.mobile .TableSearch__search-input {
	top: 10px;
	height: 30px;
}

.TableSearch__search-input::-webkit-input-placeholder {
  color: #aeaeae;
}
html.dark .TableSearch__search-input::-webkit-input-placeholder {
  color: #666;
}

html.dark .TableSearch__search-input {
	background-color: #222;
	color: white;
}

.TableSearch__search-input:disabled {
	opacity: 1;
	cursor: pointer;
}

.TableSearch__selected-floor {
	position: absolute;
	top: 20px;
	left: 24px;
}


html.mobile .TableSearch__selected-floor {
	display: none;
}

.TableSearch__back-button {
	position: absolute;
	top: 20px;
	right: 26px;
	font-size: 14px;
	color: #808080;
	transition: ease all 0.2s;
}

.TableSearch__back-button:hover {
	transform: scale(1.02) translateX(-1px);
	color: #444;
}

html.dark .TableSearch__back-button:hover {
	transform: scale(1.02) translateX(-1px);
	color: #ccc;
}

.TableSearch__back-button::after {
	content: '';
	background-size: cover;
	position: absolute;
	top: 6px;
	left: -12px;
	height: 8px;
	width: 8px;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01IDFMMSA1TDUgOSIgc3Ryb2tlPSIjODA4MDgwIi8+Cjwvc3ZnPgo=");
	background-position: center;
	background-repeat: no-repeat;
}

html.mobile .TableSearch__back-button {
	display: none;
}

.TableSearch__table-list {
	position: absolute;
	top: 100px;
	width: 100%;
	height: 0;
	overflow-y: scroll;
	background-color: white;
	border-radius: 0 0 4px 4px;
	transition: ease all 0.2s;
	pointer-events: none;
}

html.dark .TableSearch__table-list {
	background-color: #111;
}

.TableSearch.active .TableSearch__table-list {
	height: 200px;
	pointer-events: initial;
}

html.mobile .TableSearch__table-list {
	top: 50px;
	height: 240px;
	border-radius: 0;
	opacity: 0;
	padding-bottom: env(safe-area-inset-bottom);
}

html.mobile .TableSearch.active .TableSearch__table-list {
	height: 224px;
	opacity: 1;
}
