.FloorMap {
	width: 100%;
  height: 100%;
	background-color: #3b3b3b;
	position: absolute;
	overflow: hidden;
    cursor: grab;
}

.FloorMap__floor-plan-image {
	position: relative;
}

.FloorMap__draggable_container {
	position: relative;
	width: fit-content;
  transition: ease transform 0.3s;
}

.FloorMap__draggable_container .idk {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.01);
  transform-origin: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.FloorMap__draggable_container .idk::after {
  content: '';
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: rgba(255,128,128,0.75);
  transform: scale(1000);
}


.FloorMap__black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: block;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 7;

  pointer-events: none;

  opacity: 0;

  transition: ease opacity 0.5s;
}

.FloorMap__draggable_container.table-open .FloorMap__black-overlay {
  opacity: 0;
}