.FloorPlanEditor__Table__table-container{
    position: relative;
    height: 100%;
    width: 100%;
    transition: ease all 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FloorPlanEditor__Table__table-container::after {
    content:attr(css-size);
    position: absolute;
    top: calc(100% + 12px);
    width: max-content;
    text-align: center;
    background-color: #0e99ff;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    transition: ease all 0.1s;
    opacity: 0;
    pointer-events: none;
    cursor: unset;
}

.FloorPlanEditor__Table.selected .FloorPlanEditor__Table__table-container::after {
    opacity: 1;
}


.FloorPlanEditor__Table.selected .FloorPlanEditor__Table__table-container {
    outline: 1px solid #aaa;
    outline-offset: 4px;
    border-radius: 4px;
    transform: scale(1);
    box-shadow: 0 5px 20px 5px rgba(255,255,255,0.1);
    z-index:101;
}

.FloorPlanEditor__Table__table-container .FloorPlanEditor__Table__table-temp {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: white;
    border-radius: 8px;
}

.FloorPlanEditor__Table .react-resizable-handle {
    opacity: 0;
    transition: ease opacity 0.2s;
    pointer-events: none;
}

.FloorPlanEditor__Table.selected .react-resizable-handle {
    opacity: 1;
    pointer-events: initial;
}

.FloorPlanEditor__Table{
    position: absolute;
   /* left:10px;*/
}

.FloorPlanEditor__Table::before{
    content:"";
    position:absolute;
    top:-10000px;
    width: 100%;
    height: 20000px;
    left:0;
    pointer-events: none;
    border-left: none;
    border-right: none;
    opacity: 1;
}

.FloorPlanEditor__Table[css-snap-left="true"]::before{
    border-left:1px solid white !important;
}

.FloorPlanEditor__Table[css-snap-right="true"]::before{
    border-right:1px solid white !important;
}

.FloorPlanEditor__Table::after{
    content:"";
    position:absolute;
    top:0;
    width: 20000px;
    height: 100%;
    left:-10000px;
    pointer-events: none;
    border-top: none;
    border-bottom: none;
    opacity: 1;
}

.FloorPlanEditor__Table[css-snap-top="true"]::after{
    border-top:1px solid white !important;
}

.FloorPlanEditor__Table[css-snap-bottom="true"]::after{
    border-bottom:1px solid white !important;
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
    z-index: 102;
    opacity: 1;
}

html.dark .react-resizable-handle {
    filter: brightness(0) invert(0.8);
}

