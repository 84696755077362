.BuildingSummaryMobileHeader__static-section {
	position: relative;
	height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.BuildingSummaryMobileHeader__image-container {
	position: fixed;
    top: 0;
    height: calc(230px + env(safe-area-inset-top));
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BuildingSummaryMobileHeader__image {
	position: absolute;
	top: 0;
	width: 100%;
	height: 130%;
	z-index: 1;
	filter: brightness(0.7);
	display: block;
	background-size: cover;
}

.BuildingSummaryMobileHeader__title {
	position: absolute;
	z-index: 2;
	bottom: 30px;
	color: white;
	font-size: 36px;
	margin: 0;
	padding: 0;
}

.BuildingSummaryMobileHeader__header {
	position: fixed;
    top: 0;
    height: calc(44px + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
    width: 100vw;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BuildingSummaryMobileHeader__header-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: white;
    border-bottom: 1px solid #ddd;
    opacity: 0;
}

html.dark .BuildingSummaryMobileHeader__header-background {
	background-color: #111;
    border-bottom: 1px solid #222;
}

.BuildingSummaryMobileHeader__header-title {
	font-size: 18px;
	font-weight: 700;
	margin: 0;
	z-index: 100;
	opacity: 0;
}

html.dark .BuildingSummaryMobileHeader__header-title {
	color: white;
}

.BuildingSummaryMobileHeader__back-button {
	position: absolute;
	left: 28px;
	margin: 0;
	color: #888;
	font-size: 16px;
	height: 44px;
	line-height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.BuildingSummaryMobileHeader__back-button.top {
	z-index: 101;
	color: #fff;
}

.BuildingSummaryMobileHeader__back-button:link {
	color: #888;
}

.BuildingSummaryMobileHeader__back-button::after {
	content: '';
	background-size: cover;
	position: absolute;
	left: -15px;
	height: 13px;
	width: 10px;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01IDFMMSA1TDUgOSIgc3Ryb2tlPSIjODA4MDgwIi8+Cjwvc3ZnPgo=");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.BuildingSummaryMobileHeader__back-button.top::after {
	filter: brightness(0) invert(1);
}

