.FloorPlanEditor__TagsSection {
	
}

.FloorPlanEditor__TagsSection__container {
	padding: 0 14px 14px 14px;
}

.FloorPlanEditor__TagsSection__tags {
	background-color: #fff;
	height: 80px;
	border-radius: 5px;
	overflow-y: auto;
	box-shadow: 0 0 5px 5px rgba(0,0,0,0.02);
}

html.dark .FloorPlanEditor__TagsSection__tags {
	background-color: #161616;
	box-shadow: 0 0 5px 5px rgba(0,0,0,0.05);
}

.FloorPlanEditor__TagsSection__tag {
	position: relative;
	display: block;
	width: 100%;
	display: flex;
	align-items: center;
	height: 30px;
	cursor: pointer;
}

.FloorPlanEditor__TagsSection__tag::after {
	position: absolute;
	bottom: -1px;
	left: 14px;
	right: 14px;
	height: 1px;
	background-color: #ddd;

	content: "";
}

html.dark .FloorPlanEditor__TagsSection__tag::after {

	background-color: #2a2a2a;
}

.FloorPlanEditor__TagsSection__tag:last-child::after {
	background-color: transparent;
}

.FloorPlanEditor__TagsSection__tag-title {
	position: absolute;
	left: 14px;
	font-size: 12px;
	color: #444;
}

html.dark .FloorPlanEditor__TagsSection__tag-title {
	color: #aaa;
}

.FloorPlanEditor__TagsSection__tag-checkbox {
	position: absolute;
	right: 14px;
	height: 14px;
	width: 14px;

	appearance: none;

	background-color: transparent;
	border: 1px solid #666;
	border-radius: 3px;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	pointer-events: none;
}

.FloorPlanEditor__TagsSection__tag-checkbox::before {
	content: "";
	width: 60%;
	height: 60%;
	transition: ease all 0.2s;
	box-shadow: inset 1em 1em #ddd;
	transform: scale(0);

  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}


.FloorPlanEditor__TagsSection__tag-checkbox:checked::before {
	transform: scale(1);
}
