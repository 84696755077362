.FloorPlanEditor__ItemsSection {
	
}

.FloorPlanEditor__ItemsSection__container {
	height: 70px;
	padding-bottom: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.FloorPlanEditor__ItemsSection__floor-map-table-container {
	position: relative;
	width: 45px;
	height: 45px;
}