.navContainer{
    max-width: 100vw;
    height: 100px;
    background-color: #585858;
    position: relative;
    align-items: center;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%
}
html.mobile .navContainer{
    position: fixed;
}
.back{
    padding-left: 10px
}
.backSection{
    display: flex;
    justify-content: left;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    color:#CDCDCD;
    padding: 10px;
    cursor: pointer;
}
.dropdown{
    align-self: center;
    margin-top: 20px;
}
.floor{
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    border-radius: 4px;
    margin-top:5px
}
.floorTitle{
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
}
.devicesText{
    font-size: 30px;
    font-family: 'Lexend', sans-serif;
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 50px;
}
.alertsText{
    font-size: 30px;
    font-family: 'Lexend', sans-serif;
    padding: 40px;
    display: flex;
    justify-content: space-between;
}
.deviceArrow{
    padding-right: 10px;
}
.device{
    display: flex;
    justify-content: left;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    background-color: #ececec;
}
html.mobile .device{
    flex-direction: column;
}
html.mobile .deviceName{
    margin-bottom: 10px;
    display: inline;
    align-self: center;
    border-bottom: 1px solid #CDCDCD; 
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
}
html.mobile .runningTime{
    border-left: 0px;
}
html.mobile .floorsSection{
    border-top: 1px solid #CDCDCD;
    border-right: 0px;
    width:100%;
    align-self: center;
}
html.mobile .devicesSection{
    justify-items: center;
    margin-left: 0px;
    padding-left: 0px;
}
.alert{
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 4px;
    min-height: 150px;
}
.redTriangle{
    background: linear-gradient(135deg,#EB0B1C 12.5%,#fff 0);
}
.yellowTriangle{
    background: linear-gradient(135deg,#FFC93E  12.5%,#fff 0);
}
.alertIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    border-right: 1px solid #CDCDCD;
    margin: 10px;
}
.warningIcon{
    width: 60%;
    height: 60%;
}
.warningBody{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.runningTime,.batteryPercentage{
    border-left: 1px solid #CDCDCD;
    display: flex;
}
.deviceName{
    width: 30%;
}
.runningTime{
    width:90%;
}
.deviceData{
    width: 70%;
    display: flex;
}
html.mobile .deviceData{
    align-self: center;
}
.opentrue{
    transform: rotate(-270deg);
}
.floorsSection{
    margin-top: 20px;
    min-width: 67%;
    border-right: 1px solid #CDCDCD;
}
.alertsSection{
    margin-top: 20px;
    min-width: 33%;
}
html.mobile .alertsSection{
    margin-top: 120px;
}
.batteryIcon{
    transform: rotate(270deg);
}
.deviceIcon{
    padding-left: 10px;
    padding-right: 10px;
}
.ReactCollapse--collapse {
    transition: height 500ms;
}
.mainSection{
    background-color: white;
    display: flex;
    flex-direction:row;
    justify-content:inherit;
}
html, body{
    background-color: white;
}
html.mobile .mainSection{
    display: flex;
    flex-direction:column;
    justify-content:inherit;   
}

.filtersPanel{
    display: flex;
    flex-direction: column;
    z-index: 5;
}
.filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.colourFilters,.typeFilters{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
}
.colourFilters{
    min-height: 60px;
    border-bottom: 1px solid #CDCDCD;
}
.typeFilters{
    padding-top: 10px;
    min-height: 160px;
}
.scroll{
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.scroll::-webkit-scrollbar {
    display: none;
}
.scrollableAlerts{
    max-height: 70vh;
}
.dismissAlert{
    padding-top: 10px;
    padding-right: 20px;
    cursor: pointer;
}
.alertFilter{
    cursor: pointer;
}
.devicesSection{
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 70vh
}
.rotate-image {
    transition: transform 0.5s ease;
}
html, body {
    scrollbar-width: none;
}
.tables {
    max-height: 30vh;
    margin-top: 10px;
}