@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
	scroll-behavior: smooth;
}

#MarketingPage {
	background-color: white;
}

.m_hero_container{
	height: 950px;
	max-height: 1300px;
	background: radial-gradient(78.49% 78.49% at 50% 50%, rgba(34, 103, 184, 0.39) 0%, rgba(217, 217, 217, 0) 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-family: 'Lexend', sans-serif;
	margin-bottom: 300px;
	position: relative;
}

.m_nav_bar{
	padding: 20px 45px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: 'Lexend', sans-serif;
	position: fixed;
	z-index: 100;
	transition: 500ms;
}

@media only screen and (max-width: 600px){
    .m_nav_bar{
		justify-content: center;
		align-items: center;
		gap: 20px;
    	flex-direction: row-reverse;
	}

	.m_hero_container{
		margin-bottom: 150px;
	}
}

.m_nav_bar.scrolled{
	background-color: #ffffff82;
	-webkit-box-shadow: 0px 10px 29px -12px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 10px 29px -12px rgba(0,0,0,0.5);
	box-shadow: 0px 10px 29px -12px rgba(0,0,0,0.5);
}

.m_brand_name{
	font-size: 28px;
}

.m_a_tag{
	text-decoration: none;
	all: unset;
	cursor: pointer;
}

.m_a_tag:hover{
	text-decoration: none;
	all: unset;
	cursor: pointer;
}

.m_nav_links{
	display: flex;
}

.m_nav_link{
	font-size: 18px;
}

.m_hero_text_section{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.m_hero_text{
	font-size: 38px;
	text-align: center;
	padding: 0;
	margin: 0;
	font-weight: 400;
	line-height: 50px;
	max-width: 700px;
	width: 80%;
}

.m_hero_text > span{
	font-weight: 600;	
}

.m_hero_cta{
	background-color: #56AFE1;
	color: white;
	padding: 12px 14px;
	font-size: 20px;
	border-radius: 5px;
	outline: none;
	border: none;
	cursor: pointer;
	margin-top: 30px;
	font-family: 'Lexend', sans-serif;
	font-weight: 300;
}

.m_hero_bottom_section{
	height: 40%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.m_hero_page_preview{
	height: 430px;
	width: 360px;
	border-radius: 10px;
	box-shadow: 0px 0px 15px 8px rgba(58, 102, 214, 0.1);
	position: absolute;
}

.m_preview_1{
	transform: translate(-90px, 20px);
	background: url(../../../public/buildingPagePreview.png);
	background-position: center;
    background-size: cover;
}

.m_preview_2{
	transform: translate(110px, 170px);
	background: url(../../../public/floorpagepreview.png);
	background-position: center;
    background-size: cover;
}

@media only screen and (max-width: 750px){
    .m_preview_2{
		display: none;
	}

	.m_preview_1{
		transform: translate(0, 40px);
		width: 90%;
		max-width: 360px;
	}

	.m_hero_text{
		font-size: 30px
	}
}

.m_trusted_by_section{
	width: 100%;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.m_trusted_by_text{
	font-family: 'Lexend', sans-serif;
	font-size: 32px;
}

.m_trusted_by_logos{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
}

.m_trusted_by_logos > *{
	padding: 0px 30px;
	filter: grayscale(1);
	transition: 150ms;
}

.m_trusted_by_logos > *:hover{
	filter: none;
}

.m_sensor_expanding_container{
	display: flex;
	width: 100%;
	height: 2500px;
	position: relative;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin-bottom: 50px;
}

.m_sensor_title{
	font-family: 'Lexend', sans-serif;
	font-size: 32px;
}

.m_sensor{
	width: 100%;
	position: sticky;
	display: flex;
	justify-content: center;
	align-items: center;
}

.m_sensor canvas{
	margin-left: 18px;
}

.m_sensor p{
	position: absolute;
	color: #7B7B7B;
	font-size: 24px;
	font-family: 'Lexend', sans-serif;
	opacity: 0;
	transition: 500ms;
}

.m_sensor p.show{
	opacity: 1;
}

.m_sensor p.one{
	transform: translate(430px, -300px);
	max-width: 277px;	
}

.m_sensor p.two{
	transform: translate(-430px, -80px);
	max-width: 238px;	
}

.m_sensor p.three{
	transform: translate(430px, -30px);
	max-width: 337px;	
}

.m_sensor p.four{
	transform: translate(-400px, 180px);
	max-width: 337px;	
}

.m_tr{
	position: absolute;
	transform: translate(210px, -290px);
}

.m_tr .path{
	stroke-dasharray: 200;
  	stroke-dashoffset: -200;
	transition: 400ms;
}

.m_tr.show .path{
	stroke-dashoffset: 0;
}

.m_tl{
	position: absolute;
	transform: translate(-240px, -40px);
}

.m_tl .path{
	stroke-dasharray: 200;
  	stroke-dashoffset: 200;
	transition: 400ms;
}

.m_tl.show .path{
	stroke-dashoffset: 0;
}

.m_br{
	position: absolute;
	transform: translate(185px, -20px);
}

.m_br .path{
	stroke-dasharray: 200;
  	stroke-dashoffset: -200;
	transition: 400ms;
}

.m_br.show .path{
	stroke-dashoffset: 0;
}

.m_bl{
	position: absolute;
	transform: translate(-220px, 180px);
}

.m_bl .path{
	stroke-dasharray: 200;
  	stroke-dashoffset: 200;
	transition: 400ms;
}

.m_bl.show .path{
	stroke-dashoffset: 0;
}

.m_selling_points{
	width: 100%;
	height: 200px;
	transform: translateY(-200px);
	margin-bottom: -100px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.m_selling_point{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.m_selling_point p{
	color:#212121;
	font-family: 'Lexend', sans-serif;
	font-size: 20px;
	margin-top: 10px;
	max-width: 200px;
	text-align: center;
}


@media only screen and (max-width: 1000px){
	.m_selling_points{
		transform: translateY(-100px);
	}
}

@media only screen and (max-width: 700px){
	.m_selling_points{
		transform: translateY(0);
		margin-bottom: 0;
		flex-direction: column;
		gap: 30px;
		height: auto;
	}
}

.m_meet_the_team_section{
	width: 100%;
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 200px;
}

.m_meet_the_team_section > p{
	font-family: 'Lexend', sans-serif;
	font-size: 38px;
	margin-bottom: 40px;
}

.m_meet_the_team_imgs{
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	flex-wrap: wrap;
}

.m_team_member{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 40px;
}

.m_team_member img{
	height: 170px;
	width: 170px;
	border-radius: 500px;
	box-shadow: 0px 0px 15px 8px rgba(58, 102, 214, 0.3);
	margin-bottom: 25px;
}

.m_team_member p{
	font-family: 'Lexend', sans-serif;
	font-size: 18px;
}

.m_get_in_touch_section{
	background: linear-gradient(93.66deg, rgba(34, 103, 184, 0.1) 5.1%, rgba(217, 217, 217, 0) 95.14%);
	padding: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.m_get_in_touch_section p {
	font-family: 'Lexend', sans-serif;
	font-size: 38px;
}

@media only screen and (max-width: 700px){
	.m_get_in_touch_container{
		width: 90%;
	}

	.m_input{
		width: 100% !important;
	}

	.m_get_in_touch_section{
		padding: 30px;
	}
	
}

.m_footer{
	padding: 50px 60px;
	background-color: black;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.m_footer > .m_footer_text{
	margin: 20px;
}

.m_footer > *{
	margin: 20px;
}

@media only screen and (max-width: 420px){
	.m_footer{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
	}
}

.m_row{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.m_col{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}

.m_footer_address{
	color: white;
	font-family: 'Lexend', sans-serif;
	font-size: 14px;
	margin:0;
}

.m_footer_text{
	color: white;
	font-family: 'Lexend', sans-serif;
	font-size: 14px;
	margin:0;
}

.m_input{
	padding: 20px 25px;
	width: 400px;
	border:none;
	outline: none;
	font-family: 'Lexend', sans-serif;
	font-size: 18px;
	border-radius: 5px;
	margin-bottom: 30px;
	min-height: 50px;
	resize: vertical;
}

.m_get_in_touch_container{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center
}