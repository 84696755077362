.CollapsableSection {
	position: relative;
	width: 100%;
	max-height: 44px;
	border-bottom: 1px solid #ccc;
	overflow: hidden;
	color: black;
}

html.dark .CollapsableSection {
	color: white;
	border-bottom: 1px solid #3a3a3a;
}

.CollapsableSection.expanded {
	max-height: 10000px;
}

.CollapsableSection__top-container {
	top: 0;
	left: 0;
	width: 100%;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease all 0.2s;
}


.CollapsableSection.collapsable .CollapsableSection__top-container {
	cursor: pointer;
}

.CollapsableSection.collapsable .CollapsableSection__top-container:hover {
	background-color: rgba(100,100,100,0.08);
}

.CollapsableSection__content {
	height: calc(100% - 44px);
}

.CollapsableSection__title {
	position: absolute;
	left: 20px;
	font-size: 14px;
	margin: 0;
}

.CollapsableSection__arrow {
	position: absolute;
	right: 18px;
	height: 20px;
	width: 20px;

	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjQUZBRkFGIi8+Cjwvc3ZnPgoK");
	background-position: center;
	background-repeat: no-repeat;

	transition: ease transform 0.2s;
}

.CollapsableSection.expanded .CollapsableSection__arrow {
	transform: rotate(180deg);
}