.FloorPlanEditor {
	height: 100vh;
	width: 100vw;
	background-color: #333;
	overflow: hidden;
	color: black;
}

html.dark {
	color: white;
}

html.mobile .FloorPlanEditor{
	display: none;
}

.FloorPlanEditor__mobile{
	display: none;
}

html.mobile .FloorPlanEditor__mobile{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	flex-direction: column;
}

html.mobile .FloorPlanEditor__mobile p{
	font-family: 'Lexend';
	max-width: 300px;
	width: 80vw;
	text-align: center;
	font-size: 1.2rem;
}

.FloorPlanEditor__pane {
	position: fixed;
	width: 230px;
	background-color: #eee;
	display: flex;
	flex-direction: column;
	z-index: 200;
}

html.dark .FloorPlanEditor__pane {
	background-color: #222;
}

.FloorPlanEditor__pane.left {
	top: 50px;
	bottom: 0;
	left: 0;
	border-right: 1px solid #ccc;
}

html.dark .FloorPlanEditor__pane.left {
	border-right: 1px solid #3a3a3a;
}

.FloorPlanEditor__pane.right {
	top: 50px;
	bottom: 0;
	right: 0;
	border-left: 1px solid #ccc;
}

html.dark .FloorPlanEditor__pane.right {
	border-left: 1px solid #3a3a3a;
}

.FloorPlanEditor__center-container {
	position: absolute;
	top: 50px;
	left: 230px;
	right: 230px;
	bottom: 0;
	overflow: hidden;
}

.FloorPlanEditor__toolbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background-color: #eee;
	border-bottom: 1px solid #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 200;
}

html.dark .FloorPlanEditor__toolbar {
	background-color: #222;
	border-bottom: 1px solid #3a3a3a;
}

.FloorPlanEditor__title {
	color: white;
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}

.FloorPlanEditor__save-button {
	position: fixed;
	right: 20px;
	color: white;
	background-color: #1090f0;
	padding: 4px 12px;
	border-radius: 5px;
	transition: ease all 0.2s;
	cursor: pointer;
}

.FloorPlanEditor__save-button:hover {
	background-color: #30a0f0;
}

.FloorPlanEditor__save-button:active {
	background-color: #1080d0;
}

