.FloorPlanEditor__ToolboxTable {

}


.FloorPlanEditor__ToolboxTable__table-preview{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: grab;
}

.FloorPlanEditor__ToolboxTable__table-container{
    position: relative;
    height: 100%;
    width: 100%;
    transition: ease all 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FloorPlanEditor__ToolboxTable__table-container::after {
    content:attr(css-size);
    position: absolute;
    top: calc(100% + 12px);
    width: max-content;
    text-align: center;
    background-color: #0e99ff;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    transition: ease all 0.1s;
    opacity: 0;
}

