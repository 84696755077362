.FloorSummary {
    height: 100%;
    width: 100%;
    position: relative;
}

.FloorSummary__content {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
}

html.mobile .FloorSummary__content {
    position: fixed;
    top: calc(env(safe-area-inset-top) + 44px);
    bottom: calc(env(safe-area-inset-bottom) + 50px);
    width: 100%;
    background-color: red;
}